.hs_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 10% 10%;
}

.hs_img {
  width: 70%;
}

h1 {
  margin: 5% 0;
}

.h1-hs {
  text-transform: uppercase;
  width: 90%;
}

.p-hs {
  width: 90%;
  margin: auto;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
}

.btn-link {
  text-decoration: none;
  color: #eae5db;
}

.btn-link:hover {
  color: #eae5db;
}

@media (min-width: 768px) {
  .hs_img {
    width: 50%;
    margin-top: 5%;
  }

  h1 {
    margin: 8% 0;
  }

  .h1-hs {
    width: 70%;
  }

  .p-hs {
    width: 70%;
    margin: auto;
  }

  .btn_hs {
    margin-top: 5% !important;
  }
}

@media (min-width: 1024px) {
  .hs_container {
    padding: 0 5% 5%;
  }

  .hs_img {
    width: 30%;
    margin-top: 7%;
  }

  h1 {
    margin: 2% 0;
  }

  .h1-hs {
    width: 60%;
  }

  .p-hs {
    width: 60%;
  }

  .btn_hs {
    margin-top: 3% !important;
  }
}

@media (min-width: 1366px) {
  .hs_img {
    width: 20%;
    margin-top: 0;
  }

  h1 {
    font-size: 28px;
    margin: 2% 0 1%;
  }

  p {
    font-size: 14px;
  }

  .btn_hs {
    margin-top: 1% !important;
    width: 40% !important;
  }
}
