.om-oss-container {
  padding: 0 5% 5%;
}

.om-oss-img {
  width: 90%;
}

.p-om-oss {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
}

.justify-content-col {
  justify-content: center;
}

.padding-col {
  padding: 0;
}

@media (min-width: 1024px) {
  .om-oss-container {
    padding: 0 5% 5%;
  }

  .om-oss-img {
    width: 75%;
  }

  .padding-col {
    padding-right: 0;
  }

  .justify-content-col {
    justify-content: end;
  }
}

@media (min-width: 1700px) {
  .p-om-oss {
    font-size: 20px;
  }
}
