.tsInfo-container {
  position: relative;
  background-color: #eae5db;
  background-position: center;
  border-radius: 20px;
  padding: 8%;
  width: 90vw;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow-y: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 1000;
}

.p-tsInfo {
  font-family: "Montserrat", sans-serif;
  text-align: left;
  width: 90%;
  font-size: 14px;
  white-space: pre-line;
}

.p-tsInfo-1 {
  font-size: 18px;
}

.btn_main {
  width: 70%;
  font-size: 12px;
  margin-bottom: 0 !important;
  min-height: 40px;
}

.tsInfo-container h2 {
  text-align: center;
  text-transform: uppercase;
  width: 90%;
}

.link-tsInfo {
  color: #3f3b36;
  text-align: center;
}

@media (min-width: 1024px) {
  .tsInfo-container {
    width: 70vw;
    height: 60vh;
    padding: 3%;
  }

  .p-tsInfo {
    width: 90%;
  }

  .btn_main {
    width: 30%;
  }
}

@media (min-width: 1366px) {
  .tsInfo-container {
    width: 60vw;
    height: 70vh;
    justify-content: space-between;
  }
}
