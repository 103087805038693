.cf-container {
  padding: 0 5% 10%;
}

.form-control::placeholder {
  color: rgba(63, 59, 54, 0.5);
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
}

.form-check-label {
  font-size: 12px;
}

label {
  margin-top: 3%;
}

.form-control,
.form-select {
  color: black;
  border-radius: 30px;
  border: none;
  background: rgba(63, 59, 54, 0.3);
  height: 45px;
  width: 100%;
  padding-left: 10px;
}

.form-control:focus {
  outline: none !important;
  background: rgba(63, 59, 54, 0.3);
}

.form-control:focus::placeholder {
  color: transparent;
}

.form-check-input {
  margin-top: 3%;
}

.btn-link {
  text-decoration: none;
  color: #eae5db;
}

.btn-link:hover {
  color: #eae5db;
}

@media (min-width: 768px) {
  .cf-container {
    padding: 0 5% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-control::placeholder {
    font-size: 12px;
  }

  form {
    width: 90%;
  }

  label {
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  .cf-container {
    padding: 5% 5%;
  }

  .form-control::placeholder {
    font-size: 14px;
  }

  form {
    width: 80%;
  }

  .form-check-label {
    font-size: 14px;
  }
}
