.footer-container {
  display: flex;
  flex-direction: column;
  gap: 5%;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #eae5db;
  padding-top: 1%;
}

.a-footer {
  color: #3f3b36;
  font-style: italic;
  font-size: 12px;
}

.p-footer {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  margin-bottom: 0;
}

@media (max-width: 560px) {
  .a-footer {
    font-size: 8px;
  }

  .p-footer {
    font-size: 8px;
  }
}
