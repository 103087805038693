@font-face {
  font-family: "ITC Benguiat";
  src: url("fonts/BenguiatStd-Book.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "ITC Benguiat", serif;
  color: #3f3b36;
  background-color: #eae5db;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 19px;
}

p {
  font-size: 16px;
}

.btn_main {
  width: 30%;
  height: 40px;
  border: none;
  border-radius: 20px;
  background-color: #3f3b36;
  color: #eae5db;
  margin: 2% 0;
}

.btn_main:hover {
  opacity: 0.7;
}

@media (max-width: 576px) {
  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 16px;
  }

  p {
    font-size: 12px;
  }

  .btn_main {
    width: 90%;
    margin: 5% 0;
  }
}
