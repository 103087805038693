.logo {
  display: flex;
  justify-self: center;
  width: 60%;
}

.nav-item {
  padding: 0 3%;
}

.nav-link {
  padding-bottom: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}

.navbar {
  background-color: #eae5db !important;
  --bs-navbar-toggler-focus-width: 0.05rem;
}

.navbar-toggler-icon {
  width: 1em;
  height: 1em;
}

.container {
  max-width: 100%;
}

@media (max-width: 560px) {
  .nav-link {
    font-size: 14px;
  }

  .logo {
    width: 50%;
  }
}

