.sm-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.h2-scs {
  width: 80%;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .h2-scs {
    width: 60%;
  }
}
