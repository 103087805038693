.faq-container {
  padding: 0 5% 5%;
}

.p-faq {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  text-align: left;
  white-space: pre-line;
}

.accordion-button {
  background-color: #eae5db !important;
  font-size: 20px;
}

.accordion-collapse {
  background-color: #eae5db !important;
}

.accordion-item {
  border-bottom: 0.5px solid #3f3b36;
}

.accordion {
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-active-color: none;
  --bs-accordion-active-bg: none;
}

@media (max-width: 560px) {
  .faq-container {
    padding: 0 5% 10%;
  }

  .accordion-button {
    font-size: 16px;
  }
}
